
.widget canvas {
  position: relative;
  z-index: 1;
}

.label-doughnut {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  text-align: center;
  transform: scale(0.8);
}

.label-doughnut h5 {}

.watcher .watcher-details {
  font-size: 0.1rem;
  position: relative;
  transform: scale(0.8);
  transform-origin: center top;
}

.watcher .watcher-details.hide {
  display: none;
}

.watcher .watcher-demographic {
  padding: 5px 5px 1px 5px;
  background-color: black;
  color: white;
  border-radius: 0.60rem 0.60rem 0 0;
  text-transform: capitalize;
  text-indent: 28px;
  font-weight: bold;
}

.watcher .watcher-mood {
  padding: 1px 5px 5px 5px;
  background-color: white;
  text-transform: uppercase;
  text-indent: 28px;
  font-weight: bold;
  position: relative;
}

.watcher .watcher-mood img {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 20px;
  top: 0px;
  z-index: 2;
}

.watcher .watcher-demographic-avatar {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
  z-index: 1;
}
