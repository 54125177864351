// Here you can add other styles

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.loading {
  position: relative;
  overflow: hidden;
  background-color: #EFEFEF;
}

.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #EFEFEF, rgba(255,255,255,.8), #EFEFEF);
  animation: Gradient 1500ms ease-in-out infinite;
}

@keyframes Gradient {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* ----------------- DOODADS ----------------- */

.text-header {
  color: #159EC3;
}

.text-header a {
  color: #159EC3;
  vertical-align: text-top;
}

.text-muted-alt {
  color: #23282c;
}

.form-control.is-valid, .form-control.is-invalid {
  background-image: none;
}

a:hover {
}

.tooltip-custom-position {
  // left: 120px !important;
  // top: -10px !important;
  // width: 200px;
  // z-index: 10000;
}

.badge-status {
  width: 16px;
  height: 16px;
  line-height: 10px;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.expand-plus {
  padding: 3px;
  width: 20px !important;
  height: 20px;
}

.filter-card {
  font-size: 0.75rem;
  z-index: 2;
}

.filter-card .btn {
  font-size: 0.75rem;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.pagination .btn {
  width: 31px;
}

.pagination .disabled .btn {
  opacity: 0.65;
  color: #159EC3;
  background-color: transparent;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .flex-grow-calc-adjust {
    height: auto !important;
  }
}

.margin-checkbox {
  margin: -4px 1px;
}

.toastr {
  position: fixed !important;
  top: 50px;
  right: 4px;
  z-index: 99999;
}
.nodata-table {
  position: absolute;
    width: 100%;
    text-align: center;
    background: white;
    color: #877979;
    height: 30px;
    top:40%;
}

.tooltip-custom {
  // margin-left: -20px !important;
}

.tooltip-question {
  // padding: 1.750px 0px !important;
  // font-size: 0.75rem;
}

.tooltip {
  // background-color: #707070 !important;
  // opacity: 1 !important;
}

.tooltip::before, .tooltip::after {
  // border-right-color: #707070 !important;
  // border-left-color: #707070 !important;
}

.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

*:focus, *:hover {
  outline: none !important;
}

.react-tel-input .form-control {
  font-size: 0.875rem !important;
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #e4e7ea !important;
  padding: 0 0 0 4rem !important;
}

.react-tel-input .form-control.is-invalid {
  border-color: #D21616 !important;
}

.ant-notification {
  z-index: 1030 !important;
}

.bentoCol:hover {
  background-color: #e7f7ff;
  cursor: pointer;
  border-radius: 4px;
}

.bentoBtn:hover, .bentoBtn:active {
  background-color: #f8f8f8;
}

.disabled-link {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}

.center_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.SortableHelper {
  td {
    padding: 8px;
    height: 80px;
    vertical-align: middle;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

.react-datepicker-wrapper {
  display: unset !important;
}

// .react-time-picker__wrapper {
//   border: unset !important;
// }

.rc-time-picker-input {
  display: unset !important;
  padding: unset !important;
  height: unset !important;
  font-size: inherit !important;
  line-height: unset !important;
  background-color: unset !important;
  border-radius: unset !important;
  border: unset !important;
}
